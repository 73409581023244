import React, { useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import {
  Box, Heading, Text, Center, Divider, Stack, Button, Flex,
} from '@chakra-ui/react';

import Layout from '../components/layout';
import MarkdownContent from '../components/MarkdownContent';
import AsideBox from '../components/AsideBox';

import { defaultStyles, workAheadStyles } from '../styles';
import { mapDataToVariants, defaults, ping } from '../util';

const PageLanding = ({
  data, params, location, pageContext,
}) => {
  const { variantName } = pageContext;
  const variants = mapDataToVariants(data.allAirtable.nodes, {
    description: 'description',
    title: 'title',
    headline: 'headline',
    body: 'body',
    explainer: 'explainer',
    cta: 'cta',
    ctaUrl: 'cta_url'
  });
  console.log(variantName);
  // const content = defaults(variants.default, variants[variantName]);
  const content = variants[variantName];

  useEffect(ping, []);

  return (
    <Layout title={content.title} description={content.description} location={location}>
      <Heading size="3xl" fontWeight="100" mb="10">{content.headline}</Heading>
      <Box>
        <MarkdownContent text={content.body} />
      </Box>
      <Divider my="10" />

      <Box py="8" bg="white" mb="10" sx={{ ...workAheadStyles.fullWidthBackground }}>
        <Flex>
          <Box maxWidth="50%">
            <MarkdownContent text={content.explainer} />
          </Box>
          <Flex flex="1" justifyContent="flex-end">
            <Button as={Link} to={content.ctaUrl} colorScheme="orange" size="lg">{content.cta}</Button>
          </Flex>
        </Flex>
      </Box>

      <AsideBox>
        <Heading size="l" mb="4" color="gray" textTransform="uppercase">What we believe in</Heading>
        <Stack>
          <Text><b>Profesional tools should be available to everyone. </b>Our objective and holistic view makes even the most complicated professional questions and data simple to understand. Our goal is to set you on an informed path towards a balanced future.</Text>
          <Text><b>Personalized for you. </b>Tell us what matters to you and we'll recommend things like the best places to live to support your career, simple ways to rebalance your path forward and more.</Text>
          <Text><b>No nonsense. </b>Straightforward questions, smart calculators and honest language help you make sense of your options.</Text>
        </Stack>
      </AsideBox>
    </Layout>
  );
};

export default PageLanding;

export const pageQuery = graphql`
  query LandingPageQuery(
    $pageName: String!
){
    allAirtable(filter: {table: {eq: $pageName}}) {
      nodes {
        data {
          description
          title
          headline
          body
          explainer
          cta
          cta_url
          variant {
            data {
              name
            }
          }
        }
      }
    }
  }
`;
