import React from 'react';
import unified from 'unified';
import markdown from 'remark-parse';
import { sanitize } from 'rehype-sanitize';
import remark2rehype from 'remark-rehype';
import rehype2react from 'rehype-react';
import {
  Box,
  Text,
  Heading,
  Divider,
  Link,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Code,
  OrderedList,
  UnorderedList,
  ListItem
} from '@chakra-ui/react';

// mappings inspired from: https://github.com/mustaphaturhan/chakra-ui-markdown-renderer/blob/master/src/index.tsx
const options = {
  createElement: React.createElement,
  components: {
    div: Box,
    p: Text,
    hr: Divider,
    table: Table,
    thead: Thead,
    tbody: Tbody,
    tr: Tr,
    td: Td,
    th: Th,
    pre: Code,
    ul: UnorderedList,
    ol: OrderedList,
    li: ListItem,
    ...['i', 'u', 'b', 'strong', 'em', 's', 'del']
      .reduce((ob, key) => (
        {
          ...ob,
          [key]: ({ children }) => <Text as={key}>{children}</Text>,
        }
      ),
      {}),
    ...['h1', 'h2', 'h3', 'h4']
      .reduce((ob, key, level) => (
        {
          ...ob,
          [key]: ({ children }) => (<Heading as={['h1', 'h2', 'h3', 'h4'][level]} fontSize={['6xl', '3xl', 'xl', 'lg'][level]}>{children}</Heading>),
        }
      ),
      {}),
  },
};

const processor = unified()
  .use(markdown)
  .use(remark2rehype)
  .use(rehype2react, options)
  .use(sanitize);

const MarkdownContent = ({ text }) => <>{processor.processSync(text).result}</>;

export default MarkdownContent;
